/*eslint-disable */
// import SubscriptionPlanModel from '../../Model/Subscription'
import request from '../../../Utils/curl'
import Utility from "../../../Utils/utility"

let SubscriptionPlans = {
  /**
   * subscriptionPlanList
   */
  async subscriptionPlanList (context, payloadObj = {}) {
    Utility.showLoader = true;
    Utility.loadingMsg = "Please wait....";   
    
    try {
      let post_data = new FormData();
      for (let key in payloadObj) {
        if (payloadObj[key]) {
          post_data.append(key, payloadObj[key])  
        }
      }
  
      return await request.curl(context, "subscriptionPlan_list", post_data, process.env.VUE_APP_SUBSCRIPTION_API)
        .then(async response => {
          return response
        });
    }
    catch(err){
      console.error('Exception occurred at subscriptionPlanList() and Exception:',err.message)
    }
    finally{
      Utility.showLoader = false;
    }
  },

  /**
   * subscriptionAddOnPlanList
  */
  async subscriptionAddOnPlanList (context, payloadObj = {}) {
    Utility.showLoader = true;
    Utility.loadingMsg = "Please wait....";
    try {
      let post_data = new FormData();
      for (let key in payloadObj) {
        if (payloadObj[key]) {
          post_data.append(key, payloadObj[key])  
        }
      }
  
      return await request.curl(context, "subscriptionAddOnPlan_list", post_data, process.env.VUE_APP_SUBSCRIPTION_API)
        .then(async response => {
          return response
        });
    }
    catch(err){
      console.error('Exception occurred at subscriptionAddOnPlanList() and Exception:',err.message)
    }
    finally{
      Utility.showLoader = false;
    }
  },
  /**
   * subscriptionPlanView
   */
  async subscriptionPlanView (context, subscriptionPlanId) {
    try {
      let post_data = new FormData();
      post_data.append('subs_plan_id', subscriptionPlanId);
      return await request.curl(context, "subscriptionPlan_view", post_data, process.env.VUE_APP_SUBSCRIPTION_API)
      .then(async response => {
        return response;
      });
    }
    catch (err) {
      console.error("Exception occurred at subscriptionPlanView() and Exception:",err.message)
    }
    
  },

  /**
   * subscriptionPlanView
   * get plan based on module type and module obj id
  */
  async subscriptionPlanViewCustom (context, moduleName, moduleObjId = null, payloadObj = {}, subModuleName = null, userId = null, userRole = null) {
    try {
      let post_data = new FormData();
      post_data.append('mod_name', moduleName);

      if (subModuleName) {
        post_data.append('sub_module_name', subModuleName)
      }

      if (moduleObjId) {
        post_data.append('module_obj_id', moduleObjId);
      }

      if (userId) {
        post_data.append('user_id', userId);
      }

      if (userRole) {
        post_data.append('user_role', userRole);
      }

      for (let key in payloadObj) {
        if (payloadObj[key]) {
          post_data.append(key, payloadObj[key])  
        }
      }

      return await request.curl(context, "subscriptionPlan_view_custom", post_data, process.env.VUE_APP_SUBSCRIPTION_API)
      .then(async response => {
        return response;
      });
    }
    catch (err) {
      console.error("Exception occurred at subscriptionPlanView() and Exception:",err.message)
    }
  },
  
  /**
   * subscriptionPlanListCustom
   * get plan based on module type and module obj id
  */
  async subscriptionPlanListCustom (context, moduleObjId) {
    try {
      let post_data = new FormData();
      post_data.append('sub_plan_module_obj_id', moduleObjId);

      return await request.curl(context, "subscriptionPlan_list_custom", post_data, process.env.VUE_APP_SUBSCRIPTION_API)
      .then(async response => {
        return response;
      });
    }
    catch (err) {
      console.error("Exception occurred at subscriptionPlanListCustom() and Exception:",err.message)
    }
  },

  /**
   * subscriptionPlanAdd
   */
  async subscriptionPlanAdd (context, subscriptionPlanObj) {
    try{
    let post_data = new FormData();
    
    for (let key in subscriptionPlanObj) {
      if (subscriptionPlanObj[key] && typeof subscriptionPlanObj[key] != 'number') {
        post_data.append(key, subscriptionPlanObj[key]);
      }
      else if (typeof subscriptionPlanObj[key] == 'number') {
        post_data.append(key, subscriptionPlanObj[key]);
      }
    }

    return await request.curl(context, "subscriptionPlan_add", post_data, process.env.VUE_APP_SUBSCRIPTION_API)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at subscriptionPlanAdd() and Exception:',err.message)
    }
  },

  /**
   * subscriptionPlanEdit
   */
  async subscriptionPlanEdit (context, subscriptionPlanObj) {
    try{
    let post_data = new FormData();
    
    for (let key in subscriptionPlanObj) {
      if (subscriptionPlanObj[key] && typeof subscriptionPlanObj[key] != 'number') {
        post_data.append(key, subscriptionPlanObj[key]);
      }
      else if (typeof subscriptionPlanObj[key] == 'number') {
        post_data.append(key, subscriptionPlanObj[key]);
      }
    }

    return await request.curl(context, "subscriptionPlan_edit", post_data, process.env.VUE_APP_SUBSCRIPTION_API)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at subscriptionPlanEdit() and Exception:',err.message)
    }
  },

  /**
   * subscriptionPlanDelete
   */
  async subscriptionPlanDelete (context, subscriptionPlanId) {
    try{
    let post_data = new FormData();
    
    post_data.append('subs_plan_id', subscriptionPlanId);

    return await request.curl(context, "subscriptionPlan_delete", post_data, process.env.VUE_APP_SUBSCRIPTION_API)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.log('Exception occurred at subscriptionPlanDelete() and Exception:',err.message)
    }
  },

  /**
   * subscriptionPlanTypeList
   */
  async subscriptionPlanTypeList (context) {
    try{
      let post_data = new FormData();

      return await request.curl(context, "subscriptionPlanType_list", post_data, process.env.VUE_APP_SUBSCRIPTION_API)
        .then(async response => {
          return response
        });
    }
    catch(err){
      console.error('Exception occurred at subscriptionPlanTypeList() and Exception:',err.message)
    }
  },
  /**
   * subscriptionPlanCustomList
   */
  async subscriptionPlanCustomList (context, planObj) {
    try{
      let post_data = new FormData();
      for (const key in planObj) {
        post_data.append(key, planObj[key]);
      }
      
      return await request.curl(context, "subscriptionPlan_custom_list", post_data, process.env.VUE_APP_SUBSCRIPTION_API)
        .then(async response => {
          return response
        });
    }
    catch(err){
      console.error('Exception occurred at subscriptionList() and Exception:',err.message)
    }
  },
}

export {
  SubscriptionPlans
}
