import { Countries } from "../../FackApi/api/country"

let CountryMixin = {
  data () {
    return {
      cvCountryOptions: [],
      cvCurrencyOptions: [],
      vmSelectedSubsPlanCurrMixin: null,
      curSelectedCurrencyId: null
    }
  },
  async mounted () {
    await this.countryDistinctList()
    await this.currencyDistinctList()
  },
  methods: {
    async curSelectedCurrency (id) {
      this.curSelectedCurrencyId = id
    },
    /**
     * countryDistinctList
     */
    async countryDistinctList () {
      try {
        let matchWithQuery = "distinct country"
        let countryDistinctListResp = await Countries.countryList(this, matchWithQuery)
        if (countryDistinctListResp && !countryDistinctListResp.resp_status) {
          return false
        }
        this.cvCountryOptions = countryDistinctListResp.resp_data.data
      }
      catch (err) {
        console.error("Exception occurred at countryDistinctList() and Exception:", err.message)
      }
    },
    /**
     * currencyDistinctList
     */
    async currencyDistinctList () {
      try {
        let matchWithQuery = "distinct currency"
        let currencyDistinctListResp = await Countries.countryList(this, matchWithQuery)
        if (currencyDistinctListResp && !currencyDistinctListResp.resp_status) {
          return false
        }

        this.cvCurrencyOptions = currencyDistinctListResp.resp_data.data

        this.vmSelectedSubsPlanCurrMixin = this.cvCurrencyOptions.find((currency) => {
          return currency.id == this.curSelectedCurrencyId
        })
      }
      catch (err) {
        console.error("Exception occurred at currencyDistinctList() and Exception:", err.message)
      }
    }
  }
}

export default CountryMixin
