/*eslint-disable */
// import moduleModel from '../../Model/module'
import request from '../../Utils/curl'

let modules = {
  /**
   * moduleList
   */
  async moduleList (context) {
    try{
      let post_data = new FormData();
  
      return await request.curl(context, "module_list", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at moduleList() and Exception:',err.message)
    }
  },

  /**
   * moduleView
   */
  async moduleView (context, moduleId) {
    try {
      
      let post_data = new FormData();
      post_data.append('mod_id', moduleId);
      return await request.curl(context, "module_view", post_data)
      .then(async response => {
        return response;
      });
    }
    catch (err) {
      console.error("Exception occurred at moduleView() and Exception:",err.message)
    }
    
  },

  /**
   * moduleAdd
   */
  async moduleAdd (context, moduleObj) {
    try{
    let post_data = new FormData();
    
    for (let key in moduleObj) {
      post_data.append(key, moduleObj[key]);
    }

    return await request.curl(context, "module_add", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at moduleAdd() and Exception:',err.message)
    }
  },

  /**
   * moduleEdit
   */
  async moduleEdit (context, moduleObj) {
    try{
    let post_data = new FormData();
    
    for (let key in moduleObj) {
      post_data.append(key, moduleObj[key]);
    }

    return await request.curl(context, "module_edit", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at moduleEdit() and Exception:',err.message)
    }
  },

  /**
   * moduleDelete
   */
  async moduleDelete (context, moduleId) {
    try{
    let post_data = new FormData();
    
    post_data.append('mod_id', moduleId);

    return await request.curl(context, "module_delete", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.log('Exception occurred at moduleDelete() and Exception:',err.message)
    }
  }
}

export {
  modules
}
